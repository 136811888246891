.youTubeHeader :global(input),
.youTubeHeader :global(button) {
  margin-bottom: 0;
}

.playlistId {
  margin: auto;
  width: auto;
}

.searchForm {
  min-width: 500px;
  margin-right: 100px;

  @media (max-width: 1024px) {
    min-width: 380px;
    margin-right: 10px;
  }

  @media (max-width: 640px) {
    min-width: unset;
    margin-right: unset;
  }
}

.searchBar {
  height: 3em;
  justify-content: end;
  padding: 5px 0 0 5px;

  @media (max-width: 640px) {
    height: unset;
  }
}
.signInReminder {
  line-height: 3em;
  margin: 0;
  margin-right: 10px;
}
.youTubePlaylists {
  background-color: #500000;
}

.mainViewContainer:global(.items-list) {
  height: calc(100% - 3em);

  @media (max-width: 640px) {
    height: 100%;
  }
}
