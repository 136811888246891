.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: darkgrey;
  padding: 2px 0;
  height: 50px;

  @media (max-width: 640px) {
    display: none;
    height: calc(100vh - 50px);
    flex-direction: column;
    justify-content: space-around;

    &.open {
      display: flex;
    }

    :global .menu {
      flex-direction: column !important;
    }
  }
}
.logoContainer {
  display: flex;
  align-content: center;
  font-size: 1.475rem;

  @media (max-width: 1150px) {
    font-size: large;
  }
  @media (max-width: 800px) {
    font-size: medium;
  }  
  @media (max-width: 700px) {
    font-size: small;
  }
}

.googleSignin {
  background-image: url("/icons/btn_google_signin_dark_normal_web.png");
  width: 191px;
  height: 46px;
}
.googleSignin:focus {
  background-image: url("/icons/btn_google_signin_dark_focus_web.png");
}
.googleSignin:hover {
  background-image: url("/icons/btn_google_signin_dark_pressed_web.png");
  cursor: pointer;
}

.dropboxSignin {
  background-image: url("/icons/dropbox.svg");
  width: 191px;
  height: 46px;
}
.vinylImg {
  height: 25px;
  position: relative;
  top: -2px;
  padding-left: 15px;
  padding-right: 6px;
}

.iconLink {
  /* padding-top: 0 !important;
    padding-bottom: 0 !important; */
}
.routeIcon {
  height: 1.0em;
}

.processingNotify {
  color: black;
  background-color: lightgreen;
  text-align: center;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navListItem {
  font-size: x-large;

  @media (max-width: 1150px) {
    font-size: large;
  }
  @media (max-width: 800px) {
    font-size: medium;
  }  
  @media (max-width: 700px) {
    font-size: small;
  }
}
.navListItem :global a {
  padding: 0.6rem 0.8rem !important;

  @media (max-width: 1150px) {
    padding: 0.85rem 3px !important;
  }
  @media (max-width: 800px) {
    padding: 0.95rem 3px !important;
  }
}
.outline {
  outline: 1px solid #1779ba;
}

.notReadyAlert {
  display: none;

  @media (max-width: 640px) {
    display: block;
  }
}
