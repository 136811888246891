.playlist {
  overflow: auto;
}

.hiddenColControls {
  display: none;
}

.thead {
  background: #0a0a0a;
  display: flex;
  border: 1px solid #282828;
  height: 35px;
}
.thead .headerColumn {
  position: relative;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global(.touch-device) .thead .headerColumn,
.thead .headerColumn:hover {
  
  &.hideOnHover {
    :global(.react-contextmenu-wrapper) {
      display: none;
    }

    .hiddenColControls {
      flex: 1 1 auto;
      display: unset;
    }

    .menuBtn {
      flex: 1 1 auto;
      position: unset;
      margin-right: unset;
    }
  }

  .menuBtn:global(.invisible-control) {
    visibility: visible;
  }
}

.thead :global .react-contextmenu-wrapper {
  // padding: 0.5rem 0 0.625rem;
  flex: 1 1 auto;
}
.menuBtn {
  width: 16px;
  color: white;
  margin-right: 15px;

  position: absolute;
  right: 0;
  height: 100%;
}

.resizeColumn {
  cursor: col-resize;
  flex: 0 0 3px;
  background-color: grey;
  z-index: 5;
  height: 100%;
}
.underscoreColumn {
  /* padding: 0.5rem 0.625rem 0.625rem; */
  width: 16px;
  border-right: 2px solid grey;
}
.headerColumn.contextMenuBtnColumn {
  // 16px btn + 16px scrollbar hopefully
  // width: 32px;
}
.playlistControls {
  display: flex;
  justify-content: space-between;
}
.playlistControls :global label {
  width: 33%;
}

$row-color: #1d1d1d;
$row-background: lighten($row-color, 5%);

.contentWrapper.parentContentWrapper {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}
.contentWrapper {
  height: 100%;
  overflow: hidden;

  background-color: $row-color;

  &.oddRows {
    background-color: $row-background;
  }
}

.activeFilter {
  border: 2px solid #1779ba;
}
.activeFilterDesc {
  border: 2px dotted #1779ba;
}
