.row {
  height: 26px;
  font-size: 0.85em;
  display: flex;
  position: relative;
}
.row :global p,
.row :global input,
.row :global button {
  margin: 0;
}

.row:hover :global button.invisible-control {
  visibility: visible;
}

.rowCell {
  padding: 0 5px;

  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.rightAlignCell {
  text-align: right;
}

$row-color: #1d1d1d;
$row-background: lighten($row-color, 5%);

.evenRow {
  background-color: $row-color;

  &:hover {
    background-color: darken($row-color, 2%);
  }
}
.oddRow {
  background-color: $row-background;

  &:hover {
    background-color: darken($row-background, 2%);
  }
}
.selected {
  background-color: cadetblue !important;
}
.isDragging {
  opacity: 40%;
}

.playBtn {
  display: flex;
  width: 16px;
  font-family: initial;
  padding: 0 2px;
}
.menuBtn {
  width: 16px;
  color: white;
}

.menuOptionDelete,
:global(.react-contextmenu-item).menuOptionDelete {
  color: white;
  background-color: #cc4b37;

  &:hover {
    background-color: #9c1b07;
  }
}

/* .selected :global td:first-child {
    padding-left: 0.425rem;
}
.selected :global td {
    padding-top: 0.3rem;
    padding-bottom: 0.425rem;
}
.selected :global td:last-child {
    padding-right: 0.425rem;
} */

/* .itemMoveTarget {
    height: 0;
    transition: all 0.4s ease-out;
}
.itemMoveTarget :global p,
.itemMoveTarget :global td {
    transition: all 0.4s ease-out;
    height: 0;
    padding: 0;
    overflow: hidden;
}
.moveTargetMounted {
    height: 60px !important;
}

.moveTargetMounted :global p,
.moveTargetMounted :global td {
    height: initial;
}
.moveTargetMounted :global td {
    padding: 0.5rem 0.625rem 0.625rem;
} */
