.container {
  position: fixed;
  z-index: 100;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.article {
  right: 0;
  margin: 5px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.75);

  width: 80vw;
  max-width: 800px;

  background: hsla(200, 19%, 26%, 1);
}

.heading {
  text-align: center;
}

.btnContainer {
  display: flex;
  justify-content: center;
}
button.button:global(.button) {
  margin: auto;
  background-color: hsla(0, 0%, 100%, 0.4);
}
