.selected {
  background-color: cadetblue !important;
}

.spacedFlexContainer {
  display: flex;
  justify-content: space-between;
}
.row {
  height: 29px;
  // Element is border-box, for some reason the list jumps however
  // when hovering playlistRowEnd with scrollbar visible
  border: 2px solid #00000000 !important;
  /* padding: 5px 0; */
  position: relative;
  text-align: left;

  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;

  :global(.no-styles-link) {
    height: 100%;
  }

  button {
    cursor: unset;
  }
}
.row.noMenu {
  user-select: none;
  width: unset;
}
.row.library {
  user-select: none;
}
.row > *:first-child {

  > img,
  > button {
    white-space: unset;
    text-overflow: unset;
    overflow: unset;
  }
}

.rowBody {
  padding: 2px 2px 2px 6px;
  width: 128px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// .row.hoverDrop :global(.react-contextmenu-wrapper) {
//   padding: 0px 0px 0px 6px;
// }
.row.hoverDrop {
  color: lightgreen;
  /* very specific css border-bottom: 0 on parent*/
  border: 2px dotted lightgreen !important;
}
.row.rowEnd {
  color: lightgreen;
  /* very specific css border-bottom: 0 on parent*/
  border: 2px dotted lightgreen !important;

  &.hoverDrop {
    border: 2px solid lightgreen !important;
  }

  // The following do not work as intended anymore
  // min-height: 12px;
  // height: auto;
  .rowBody {
    user-select: none;
    padding: unset;
  }
  .menuBtn {
    height: unset;
  }
}

.row.isDragging {
  opacity: 40%;
}

.row:hover {

  .menuBtn:global(.hidden-control) {
    display: unset;
  }
}
.menuBtn {
  width: 16px;
  color: white;
  margin-right: 2px;

  // position: absolute;
  // right: 0;
  height: 100%;
}

.contextMenu {
  display: none;
  position: fixed;
  z-index: 5;

  border: 1px solid grey;
  background: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 0 0 1px #eee;
  padding: 5px 0;
}
.contextMenu.show {
  display: block;
}
.contextMenu :global ul {
  list-style: none;
  text-align: left;
  width: max-content;
  margin: 0;
}
.contextMenu :global li:hover {
  background: lightgrey;
  padding: 0 12px;
}

.deleteMenuItem:global(.react-contextmenu-item) {
  color: white;
  background-color: #cc4b37;
}
.deleteMenuItem:global(.react-contextmenu-item):hover {
  background-color: #9c1b07;
}

.playlistNameInput {
  display: inline;
}
