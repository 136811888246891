.rightControls {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding: 0 5px;
}
.bottomControls {
  display: flex;
  justify-content: center;
  /* margin-top: 5px; */
  padding: 0 5px;
  height: 24px;
  
  background-color: dimgrey;
}

.pageNumber :global select {
  margin-bottom: 0;
}
.rppLabel :global input {
  margin: 0;
}

.buttonGroup:global(.button-group) {
  padding: 5px 0 0 5px;
  margin-bottom: 0;
}

.filter {
  margin: 0;
}
