.player {
  position: relative;
}

.playbackControlsContainer {
  display: flex;
  justify-content: center;

  @media (max-width: 640px) {
    justify-content: unset;
  }
}

.audio {
  width: 70%;
  height: 42px;

  @media (max-width: 640px) {
    width: 75%;
  }
}

.smallDeviceShow {
  display: block !important;
  height: calc(100vh - 50px) !important;
}
.hidePlayer {
  display: none;
}
.small {
  height: 100px;
}
.medium {
  height: 400px;
}
.large {
  height: 600px;
}

.toggleSize {
  font-size: 40px;
}
.trackNavigation {
  font-size: 40px;
}

.spotifyPlayer {
  width: 80%;
  display: flex;
  align-items: center;
}

.spotifyPlayer :global button.button {
  width: 70px;
  margin: 0 10px;
}
.spotifyPlayer :global input[type=range] {
  flex: 1;
}
.spotifyPlayer :global .track-time {
  margin: 0 10px;
}

.controls {
  position: absolute;
  right: 0;
  display: flex;

  @media (max-width: 640px) {
    flex-direction: column-reverse;

    & > button {
      padding: 3px;
    }
  }
}
.controls :global button {
  margin: 0 5px;
}

.hiddenPlayerControls {
  position: fixed;
  bottom: 0;
  right: 0;

  padding: 0 20px 8px;
  background: rgba(128, 128, 128, 0.8);
}
