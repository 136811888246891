@import "settings";
@import "foundation-sites/scss/foundation.scss";
@import 'react-context-menu.css';

@include foundation-everything;
@include foundation-range-input;
@include add-foundation-colors;

body {
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

thead th {
    text-align: center;
}

*:focus {
    outline: none;
}
