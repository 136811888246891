.footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #808080;
}

.footer figure {
    padding: 10px;
}
