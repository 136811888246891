.mobileMenu {
  display: none;
  justify-content: space-between;
  height: 50px;

  @media (max-width: 640px) {
    display: flex;
  }

  :global .button {
    margin: 0;
  }
}
