.App {
  text-align: center;

  main {
    padding-bottom: 100px;
  }
}
.App.ViewportHeight {
  height: 100vh;
  overflow: hidden;

  main {
    padding-bottom: initial;
  }
}

.Frontpage {
  header {
    text-align: center;
  }
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.success-color {
  color: #3adb76;
}
.warning-color {
  color: #ffae00;
}

.App ul {
  list-style: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    color: #1468a0;
  }
  & img {
    border: 0;
  }
}
.no-styles-link {
    line-height: unset;
    color: unset;
    text-decoration: unset;
    user-select: none;
    cursor: default;
}
.no-styles-link:hover,
.no-styles-link:focus {
    color: unset;
}
.no-margin {
  margin: 0 !important;
}

.googleSignin {
  background-image: url("/icons/btn_google_signin_dark_normal_web.png");
  width: 191px;
  height: 46px;
}
.googleSignin:focus {
  background-image: url("/icons/btn_google_signin_dark_focus_web.png");
}
.googleSignin:hover {
  background-image: url("/icons/btn_google_signin_dark_pressed_web.png");
  cursor: pointer;
}

.mainViewport {
  height: calc(100vh - 50px);
  display: flex;
  flex-flow: column;

  &.small {
    height: calc(100vh - 50px - 100px);
  }
  &.medium {
    height: calc(100vh - 50px - 400px);
  }

  // We hide header in smaller screen sizes
  @media (max-width: 640px) {
    height: calc(100vh);

    // &.small {
    //   height: calc(100vh - 100px);
    // }
    // &.medium {
    //   height: calc(100vh - 400px);
    // }
  }

  // Unused
  &.large {
    height: calc(100vh - 50px - 600px);
  }
}

.playlist-container {
  min-height: 0;
}
.playlists {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.items-list {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.table-wrapper {
  overflow: auto;
}

.play-button {
    color: white;
    font-size: larger;
}

.icon {
  height: 16px;
  width: 16px;
}

input[type=text], input[type=checkbox], input[type=radio] {
  margin-bottom: 0;
}

// Clickable context menu and play button
.invisible-control {
  visibility: hidden;
}
.touch-device .invisible-control {
  visibility: unset;
}

.hidden-control {
  display: none;
}
.touch-device .hidden-control {
  display: unset;
}
.react-contextmenu-submenu .react-contextmenu {
  overflow-y: auto;
  max-height: 100vh;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
