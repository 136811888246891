/* .playlists :global li:nth-of-type(even) {
  border-bottom: 0;
  background-color: #f1f1f1;
} */

.playlistList {
  overflow: auto;
  margin: 0;
  height: 100%;
  width: 150px;
  background-color: #000;

  @media (max-width: 640px) {
    display: none;
    width: 100vw;
    height: calc(100vh - 50px) !important;
    z-index: 10;
    
    &.open {
      display: block;
    }
  }
}
