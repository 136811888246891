.settings {
  overflow-y: scroll;
}

.easySignin {
  margin-top: 30px;
}

.advancedBtn {
  margin-top: 100px;
}

.bigBtnGroup {
  > :first-child {
    margin-right: 1px;
  }
}
