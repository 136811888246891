.playlistImg {
  height: 28px;
  margin-right: 5px;
}
.playlistImgLarge {
  height: 64px;
}
.activePlaylist {
  outline: 2px solid grey;
}

.searchLabel {
  min-width: 500px;
  margin-right: 100px;

  @media (max-width: 1024px) {
    min-width: 380px;
    margin-right: 10px;
  }

  @media (max-width: 640px) {
    min-width: unset;
    margin-right: unset;

    :global(#query) {
      flex: none;
    }
  }
}
.searchBar {
  padding: 5px 0 0 5px;
  height: 3em;
  justify-content: end;

  @media (max-width: 640px) {
    height: unset;
  }
}
.searchBar :global input[type='checkbox'] {
  margin-left: 5px;
}
.searchTypeControls > label {
  display: unset;
}
.mainViewContainer:global(.items-list) {
  height: calc(100% - 3em);

  @media (max-width: 640px) {
    height: 100%;
  }
}
.spotifyPlaylists {
  background-color: #00290f;
  width: 210px !important;

  @media (max-width: 640px) {
    width: 100vw !important;
  }
}
.spotifyPlaylists :global li {
  padding: 0;
}
.spotifyPlaylists :global .react-contextmenu-wrapper {
  display: flex;
  padding: 2px 0 2px 2px;
}
