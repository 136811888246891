.playlistRow {
  // text-align: right !important;

  // @media (max-width: 640px) {
  //   text-align: left !important;
  // }
}

.selected {
  background-color: black !important;
}
