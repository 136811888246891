.navListItem {
    padding: 5px;
    font-size: xx-large;
}
.vinylImg {
    height: 30px;
    position: relative;
    top: -3px;
    padding-right: 6px;
}
